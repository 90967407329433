module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/images/logo.png","name":"Mayresur | Repuestos de maquinaria para obra pública y minería","short_name":"Mayresur","description":"Venta económica y rápida de material de desgaste, cuchillas y cantoneras, cazos, protecciones, rippers, tornillería, punteros, aceros, chapas, repuestos de motores, cadenas de goma y metálicas, y mucho más","start_url":"/","background_color":"#555","theme_color":"#FBC02D","display":"standalone","lang":"es","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4b2fadbd2bbd10546bccc6fae9867735"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
